import Layout from "../../Layout/Layout";
import EspaceCarriereBody from "./EspaceCarriereBody";
import EspaceCarriereOffreHeader from "./EspaceCarriereOffreHeader";

const EspaceCarriereOffre = () => {

    return (
        <Layout>
            <div>
                <EspaceCarriereOffreHeader />
                <EspaceCarriereBody />
            </div>
        </Layout>

    );

};
export default EspaceCarriereOffre; 